<script setup lang="ts">
export interface IconChevronDownProps {
  width?: number;
  height?: number;
}

const props = withDefaults(defineProps<IconChevronDownProps>(), {
  width: 12,
  height: 12,
});
</script>

<template>
  <svg :width="props.width" :height="props.height" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 7.244 2.103 5l7.875 8.104L17.852 5 20 7.198 9.978 17.5z" fill="currentColor" fill-rule="evenodd" />
  </svg>
</template>
